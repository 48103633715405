@import 'theme/palette.scss';
@import './fonts.scss';

html,
body,
#root {
    display: flex;
    // flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
    overflow-x: hidden !important;
    box-sizing: border-box !important;
    padding: 0 !important;
    margin: 0 !important;
    font-family: $fontFamily;
}

[dir] {
    body {
        font-family: $fontFamily;
    }
}
span {
    font-family: $fontFamily;
}
[dir='rtl'] {
    font-family: $fontFamily;

    body {
        font-family: $fontFamily;
    }
}
h4,
h3,
h1,
h2 {
    font-family: $Amaranth;
}

button {
    font-family: $fontFamily;
    display: flex;
    align-items: center;
    justify-content: center;
}
