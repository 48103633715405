// @font-face {
//     font-family: 'Dongle';
//     src: url('../assets/fonts/Dongle-Regular.woff') format('truetype');
//     font-weight: 500;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'Dongle';
//     src: url('../assets/fonts/Dongle-Regular.woff') format('truetype');
//     font-weight: normal;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'Dongle';
//     src: url('../assets/fonts/Dongle-Light.woff') format('truetype');
//     font-weight: 100;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'Dongle';
//     src: url('../assets/fonts/Dongle-Light.woff') format('truetype');
//     font-weight: 200;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'Dongle';
//     src: url('../assets/fonts/Dongle-Bold.woff') format('truetype');
//     font-weight: bold;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'Dongle';
//     src: url('../assets/fonts/Dongle-Bold.woff') format('truetype');
//     font-weight: 600;
//     font-style: normal;

// }

@font-face {
    font-family: 'Amaranth';
    src: url('../assets/fonts/Amaranth-Regular.woff') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Amaranth';
    src: url('../assets/fonts/Amaranth-Regular.woff') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Amaranth';
    src: url('../assets/fonts/Amaranth-Bold.woff') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Amaranth';
    src: url('../assets/fonts/Amaranth-Bold.woff') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Amaranth';
    src: url('../assets/fonts/Amaranth-Regular.woff') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Amaranth';
    src: url('../assets/fonts/Amaranth-Regular.woff') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Lato';
//     src: url('../assets/fonts/Lato-Regular.woff') format('truetype');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Lato';
//     src: url('../assets/fonts/Lato-Regular.woff') format('truetype');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Lato';
//     src: url('../assets/fonts/Lato-Light.woff') format('truetype');
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: 'Lato';
//     src: url('../assets/fonts/Lato-Light.woff') format('truetype');
//     font-weight: 200;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Lato';
//     src: url('../assets/fonts/Lato-Bold.woff') format('truetype');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: 'Lato';
//     src: url('../assets/fonts/Lato-Bold.woff') format('truetype');
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }

$fontFamily: 'Lato';
$rtlFontFamily: 'Lato';

$Amaranth: 'Amaranth';
$Lato: 'Lato';
